import React, { Fragment } from "react";
import NavBar from "../components/NavBar.jsx";
import TitleBox, { TitleBoxHeights } from "../components/TitleBox.jsx";
import MainPageGridItem, {
  getParagraphItem,
  pageWidths,
  pageMargins,
} from "../components/MainPageGridItem.jsx";
import Grid from "@material-ui/core/Grid";
import { parseWithFallback } from "../components/util/Functions";
import Footer from "../components/Footer.jsx";
import { FaCalendar, FaClock, FaFacebook } from "react-icons/fa";
import Link from "../components/util/GatsbyLink";
import { Helmet } from "react-helmet";

import { graphql } from "gatsby";

/** @jsx jsx */
import { jsx, Text } from "theme-ui";

export const query = graphql`
  query($eventID: ID!) {
    keystone {
      Event(where: { id: $eventID }) {
        paragraphs(sortBy: order_ASC) {
          title
          order
          image {
            publicUrl
          }
          content
        }
        date
        facebookEvent
        title
        subtitle
        metaDesc
      }
    }
  }
`;

const ElementsTemplate = ({ data }) => {
  const event = data.keystone.Event;
  const paragraphs = event.paragraphs;
  const date = new Date(event.date);
  return (
    <Fragment>
      <Helmet>
        <title>Evento PG - {event.title}</title>
        <meta
          name="description"
          content={"Poliambulatorio Giano - Evento - " + event.metaDesc}
        />
      </Helmet>
      <NavBar />
      <TitleBox heights={TitleBoxHeights.contentPageHeights}>
        <Text variant="pagesTitle">Notizie</Text>
      </TitleBox>
      <Grid container sx={{ height: "100%" }}>
        <MainPageGridItem xs={12} colorNumber={0} lateralPadding="0px">
          <div
            sx={{
              width: pageWidths.maxElementWidths,
              marginLeft: pageMargins.externalPageMargins,
              marginRight: pageMargins.externalPageMargins,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text variant="paragraphTitle2" sx={{ color: "black" }}>
              {event.title}
            </Text>
            {event.subtitle && <Text variant="subTitle">{event.subtitle}</Text>}
            <Text variant="note" sx={{ marginTop: "6px" }}>
              <div
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <FaCalendar sx={{ marginRight: "6px" }} />
                {date.toLocaleDateString("it-IT", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
                <FaClock sx={{ marginRight: "6px", marginLeft: "10px" }} />
                {date.toLocaleString("it-IT", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
                {event.facebookEvent && (
                  <Link to={event.facebookEvent}>
                    <Text
                      variant="note"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <FaFacebook
                        sx={{ marginRight: "6px", marginLeft: "10px" }}
                      />
                      Fonte
                    </Text>
                  </Link>
                )}
              </div>
            </Text>
          </div>
        </MainPageGridItem>
        {paragraphs.map((p, i) =>

          getParagraphItem(
            p.title,
            parseWithFallback(p.content),
            i % 2,
            i % 2 == 0,
            p.image,
            i,
            12,
            undefined,
            false
          )
        )}
      </Grid>
      <Footer />
    </Fragment>
  );
};

export default ElementsTemplate;
